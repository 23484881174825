<template>
    <div id="all">
        <img :src="'/images/flags/'+state.abbreviation.toLowerCase()+'.png'">
        <div id="main">
            <h1> {{ state.name }} </h1>
            <h2> "{{ state.nickname }}" </h2>
            Established: {{ state.date }} <br>
            Capital: {{ state.capital }} <br>
            Flower: {{ state.flower }} <br>
            <div :theData="theData" v-for="userFacts in theData" :key="userFacts.user._id">
                <div v-for="value,property in userFacts.additional_data" :key="property">
                    {{property}}: {{ value }}
                </div>
            </div>
        </div>
        <div id="add">
            <h3>Add a New Fact</h3>
            <form>
                <input v-model="factType" type="text" placeholder="Fact Type" id="facttype"> : 
                <input v-model="fact" type="text" placeholder="Fact" id="fact"> <br><br>
                <input type="button" value="Submit" @click="addFact(state)">
            </form>
        </div>
        <div v-if="user" id='dashboard'>
            <h3 style="flex-basis: 100%;"> My Facts </h3>
            <div>
            <div id="selectionBox">
                <div class="userProperty" v-for="value,property in myData.additional_data" :key="property" @click="selectItem(property, value)">
                    {{ property }}
                </div>
            </div>
            <input type="button" value="Delete All Facts" @click="deleteAllUserFacts">
            </div>
            <div>
                <form v-if="selectedItem">
                    <input v-model="editFactType" type="text" placeholder="Fact Type" id="facttype"> : 
                    <input v-model="editFact" type="text" placeholder="Fact" id="fact"> <br><br>
                    <input type="button" value="Edit Fact" @click="editUserFact()">
                    <input type="button" value="Delete Fact" @click="deleteUserFact()">
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: "StateInfo",
    props: {
        state: Object,
    },
    data() {
        return {
            fact: '',
            factType: '',
            theData: {},
            error: '',
            myData: {},
            selectedItem: false,
            editFact: '',
            editFactType: '',
            previousfactType: '',
        }
    },
    created() {
        let id = this.$route.params.id;
        let states = this.$root.$data.states;
            let i;
            for (i = 0; i < states.length; ++i) {
                if (id === states[i].abbreviation) break;
            }
        this.$root.currentState = states[i];
        this.getStateFacts();
        this.getUserStateFacts();
    },
    computed: {
        currentState() {
            if (this.$root.$data.currentState.name === "initial") return "null";
            else return this.$root.$data.currentState.additional_data;
        },
        user() {
            return this.$root.$data.user;
        }
    },
    methods: {
        addFact(state) {
            if (this.factType === "" || this.fact === "") return;
            this.addStateFact();
            state.additional_data[this.factType] = this.fact;
            let data = this.$root.$data.states;
            let i;
            for (i = 0; i < data.length; ++i) {
                if (state.name === data[i].name) break;
            }
            this.$root.$data.states[i] = state;
            this.$root.$data.state = state;
            this.factType = '';
            this.fact = '';
            this.$forceUpdate();
            this.getStateFacts();
            this.getUserStateFacts();
        },
        async getStateFacts() {
            try {
                let response = await axios.get('/api/states/' + this.$route.params.id);
                this.theData = response.data;
            } catch (error) {
                this.error = error.response.data.message;
            }
        },
        async addStateFact() {
            try {
                await axios.post('/api/states/' + this.state.abbreviation, 
                {factType: this.factType, fact: this.fact});
                this.getStateFacts();
                this.getUserStateFacts();
            } catch (error) {
                this.error = error.response.data.message;
            }
        },
        async getUserStateFacts() {
            try {
                let response = await axios.get('api/states/myfacts/' + this.$route.params.id);
                this.myData = response.data;
            } catch (error) {
                this.error = error.response.data.message;
            }
        },
        selectItem(property, value) {
            this.editFactType = property;
            this.editFact = value;
            this.previousfactType = property;
            this.selectedItem = true;
        },
        async editUserFact() {
            if (this.editFactType === "" || this.editFact === "") return;
            let updatedFacts = this.myData.additional_data;
            delete updatedFacts[this.previousfactType];
            updatedFacts[this.editFactType] = this.editFact;
            try {
                await axios.put('/api/states/' + this.$route.params.id, 
                {updatedData: updatedFacts});
                this.getStateFacts();
                this.getUserStateFacts();
                this.editFactType = "";
                this.editFact = ""
                this.previousfactType = ""
                this.selectedItem = false;
            } catch (error) {
                this.error = error.response.data.message;
            }
        },
        async deleteUserFact() {
            if (this.editFactType === "" || this.editFact === "") return;
            let updatedFacts = this.myData.additional_data;
            delete updatedFacts[this.previousfactType];
            try {
                await axios.put('/api/states/' + this.$route.params.id, 
                {updatedData: updatedFacts});
                this.getStateFacts();
                this.getUserStateFacts();
                this.editFactType = "";
                this.editFact = ""
                this.previousfactType = ""
                this.selectedItem = false;
            } catch (error) {
                this.error = error.response.data.message;
            }
        },
        async deleteAllUserFacts() {
            try {
                await axios.delete('/api/states/' + this.$route.params.id);
                this.getStateFacts();
                this.getUserStateFacts();
                this.editFactType = "";
                this.editFact = ""
                this.previousfactType = ""
                this.selectedItem = false;
            } catch (error) {
                this.error - error.response.data.message;
            }
        }
    }
}
</script>

<style scoped>
    #all {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        width: 100%;
    }
    h1 {
        font-size: 70px;
        margin: 0;
    }
    h2 {
        margin: 0;
    }
    #main {
        text-align: center;
        width: 40%;
    }
    img {
        float: left;
        height: 300px;
        border: 5px solid grey;
    }
    #add {
        margin-top: 20px;
        padding: 10px;
        flex-basis: 100%;
    }
    input[type="button"] {
        background-color: #42b983;
        color: white;
        border-radius: 10px;
        border: none;
        padding: 3px 10px;
        font-weight: bold;
        margin: 0 4px;
    }
    #dashboard {
        flex-basis: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    #selectionBox {
        margin: 20px;
        padding: 10px;
        width: 100px;
        border: solid black 1px;
    }

    .userPropertySelected {
        background-color: #42b983;
        color: #fff;
    }

    .userProperty:hover {
        background-color: #42b983;
        color: #fff;
    }
</style>