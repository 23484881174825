<template>
  <div id="app">
    <div id="header">
      <h1> 50 States Trivia </h1>
      <div>
        <button v-if='user' @click='logout'>Log Out</button>
        <button v-else @click='openLogin'>Login</button>
      </div>
    </div>
    <login :show="show" @close="close" />
    <router-view/>
    <footer>
            <a href="https://github.com/mfteuscher/Creative-Project-3" target="_blank">
                <img class="github" src="/images/GitHub-Logo-Dark.png" alt="GitHub Logo">
            </a>
            <p>16 hours spent</p>
            <p v-html="this.$root.$data.copyright"></p>
        </footer>
  </div>
</template>

<script>
import axios from 'axios';
import Login from '@/components/Login.vue';
export default {
  name: 'App',
  components: {
    Login
  },
  data() {
    return {
      show: false,
      error: ''
    }
  },
  async created() {
    try {
      let response = await axios.get('/api/users');
      this.$root.$data.user = response.data.user;
    } catch (error) {
      this.$root.$data.user = null;
    }
  },
  computed: {
      user() {
          return this.$root.$data.user;
      }
  },
  methods: {
    openLogin() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    async logout() {
      try {
        await axios.delete("/api/users");
        this.$root.$data.user = null;
        this.$router.push('/');
      } catch (error) {
        this.$root.$data.user = null;
      }
    },
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  color: white;
  background-color: #42b983;
;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #FFFF;
}

button {
    background-color: #42b983;
    color: white;
    border-radius: 10px;
    border: solid white 2px;
    padding: 3px 10px;
    font-weight: bold;
}

footer {
    text-align: center !important;
    padding: 10px 20px;
    background-color: rgb(250,250,250);
    margin-top: 50px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    bottom: 0;
    width: 100%;
}

.github {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.github:hover {
    content: url(/images/GitHub-Logo-Light.png)
}
</style>
