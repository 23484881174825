<template>
  <transition v-if="show" name="modal">
    <div class="modal-mask">
      <div class="modal-container">
          <div id='close' @click="close" class="pure-button"><img src='/images/close_icon.png'/></div>
        <form class="pure-form" @submit.prevent="upload">
          <div class="hero">
            <div class="heroBox">
              <form class="pure-form">
                <fieldset>
                  <legend>Register for an account</legend>
                  <input placeholder="First Name" v-model="firstName" />
                  <input placeholder="Last Name" v-model="lastName" />
                  <input placeholder="Username" v-model="username" />
                  <input type="password" placeholder="Password" v-model="password" />
                </fieldset>
                <fieldset class='fieldbutton'>
                  <button type="submit" class="pure-button pure-button-primary" @click.prevent="register">Register</button>
                </fieldset>
              </form>
              <p v-if="error" class="error">{{ error }}</p>
              <form class="pure-form space-above">
                <fieldset>
                  <legend>Login</legend>
                  <input placeholder="username" v-model="usernameLogin" />
                  <input
                    type="password"
                    placeholder="password"
                    v-model="passwordLogin"
                  />
                </fieldset>
                <fieldset class='fieldbutton'>
                  <button
                    type="submit"
                    class="pure-button pure-button-primary"
                    @click.prevent="login"
                  >
                    Login
                  </button>
                </fieldset>
              </form>
              <p v-if="errorLogin" class="error">{{ errorLogin }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  props: {
    show: Boolean,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      usernameLogin: "",
      passwordLogin: "",
      error: "",
      errorLogin: "",
    };
  },
  methods: {
    close() {
        this.$emit('close');
    },
    async register() {
      this.error = "";
      this.errorLogin = "";
      if (!this.firstName || !this.lastName || !this.username || !this.password)
        return;
      try {
        let response = await axios.post("/api/users", {
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          password: this.password,
        });
        this.$root.$data.user = response.data.user;
        this.$emit('close');
      } catch (error) {
        this.error = error.response.data.message;
        this.$root.$data.user = null;
      }
    },
    async login() {
      this.error = "";
      this.errorLogin = "";
      if (!this.usernameLogin || !this.passwordLogin) return;
      try {
        let response = await axios.post("/api/users/login", {
          username: this.usernameLogin,
          password: this.passwordLogin,
        });
        this.$root.$data.user = response.data.user;
        this.$router.push("/");
        this.$emit('close');
      } catch (error) {
        this.errorLogin = "Error: " + error.response.data.message;
        this.$root.$data.user = null;
      }
    },
  },
};
</script>

<style scoped>
/* Modals */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: opacity 0.5s ease;
  display: flex;
  transition: background 0.2s ease-in-out, height 1s ease-in-out;
  transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  justify-content: center;
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
}

.modal-container {
  width: 50%;
  height: max-content;
  margin-top: 80px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.5s ease;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Form */

form {
  font-size: 11pt;
}

input {
  width: 100%;
}

textarea {
  width: 100%;
  height: 100px;
}

fieldset {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    max-width: 300px;
}

input {
    flex-basis: 40%;
    padding: 5px;
}

.placeholder {
  background: #f0f0f0;
  width: 200px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 14px;
  cursor: pointer;
}

.placeholder:hover {
  background: #e0e0e0;
}

.fileInput {
  display: none;
}

img {
  width: 200px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.space-above {
  margin-top: 50px;
}

h1 {
  font-size: 28px;
  font-variant: capitalize;
}

.hero {
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 90%;
}

.heroBox {
  text-align: center;
}

.hero form {
  font-size: 14px;
}

.hero form legend {
  font-size: 20px;
}

input {
  margin-right: 10px;
}

.error {
  margin-top: 10px;
  display: inline;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 10px;
  background-color: #d9534f;
  color: #fff;
}

/*.pure-button{
    display:inline-block;
    line-height:normal;
    white-space:nowrap;
    vertical-align:middle;
    text-align:center;
    cursor:pointer;
    -webkit-user-drag:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}*/

.pure-form input:not([type]){padding:.5em .6em;display:inline-block;border:1px solid #ccc;-webkit-box-shadow:inset 0 1px 3px #ddd;box-shadow:inset 0 1px 3px #ddd;border-radius:4px;-webkit-box-sizing:border-box;box-sizing:border-box}
.pure-form input[type=date],.pure-form input[type=datetime-local],.pure-form input[type=datetime],.pure-form input[type=email],.pure-form input[type=month],.pure-form input[type=number],.pure-form input[type=password],.pure-form input[type=search],.pure-form input[type=tel],.pure-form input[type=text],.pure-form input[type=time],.pure-form input[type=url],.pure-form input[type=week],.pure-form select,.pure-form textarea{padding:.5em .6em;display:inline-block;border:1px solid #ccc;-webkit-box-shadow:inset 0 1px 3px #ddd;box-shadow:inset 0 1px 3px #ddd;border-radius:4px;vertical-align:middle;-webkit-box-sizing:border-box;box-sizing:border-box}

#close {
    height: 40px;
    width: 40px;
    float: right;
}

img {
    width: 30px;
    height: 30px;
}

.fieldbutton {
    border: none;
    padding: 10px;
}

button {
    background-color: #42b983;
    color: white;
    border-radius: 10px;
    border: none;
    padding: 3px 10px;
    font-weight: bold;
}

@media all and (max-width: 500px) {
    input {
        flex-basis: 100%;
    }
    .hero {
        padding: 2px;
    }
}
</style>
